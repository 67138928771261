.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input:checked ~ .dot {
  transform: translateX(-100%);
  background-color: #fff;
}

#modelScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f8fafc;
}

#modelScroll::-webkit-scrollbar {
  width: 5px;
  background-color: #f8fafc;
}

#modelScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cbd5e1;
}

#cartScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f8fafc;
}

#cartScroll::-webkit-scrollbar {
  width: 1px;
  background-color: #f8fafc;
}

#cartScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cbd5e1;
}

.ck-content {
  height: 200px;
}

.slick-slide > div {
  margin: 0 16px;
}

.browseDiamond .slick-slide > div {
  margin: 0 4px;
}

.details_page .slick-slide > div {
  margin: 0;
}

.details_page .slick-slide {
  padding: 0 4px;
}

.details_page .slick-list {
  margin: 0 -15px;
}

.slideHome .slick-slide > div {
  margin: 0;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

/* Firefox */
.dropdown:hover .dropdown-menu {
  display: block;
}

.swal-icon {
  width: 60px;
  height: 60px;
}

.swal-icon--warning__body {
  height: 27px;
}

.swal-title:not(:last-child) {
  margin-bottom: 2px;
  padding: 0px 16px;
  font-size: 24px;
}

.swal-footer {
  margin: 0;
}

.sideImages .bottomImg .slick-slider img {
  height: 100px !important;
}

.sideImages .bannerImg {
  overflow: hidden;
  width: 495px !important;
  height: 495px !important;
  object-fit: cover;
  box-shadow: 0px 0px 16px 5px #e9e9e9;
}

.mobImage {
  display: none;
}

.deskImage {
  display: block;
}

.slick-track {
  margin-left: 0 !important;
}

.mounting-detail-slider .slick-list .slick-track{
  height: 452px !important;
}

@media (max-width: 1280px) {
  .mobImage {
    display: block !important;
  }

  .deskImage {
    display: none;
  }
}

@media (max-width: 1024px) {
  .sideImages .bottomImg {
    display: none !important;
  }

  .sideImages .bannerImg {
    height: auto !important;
    width: auto !important;
    border: none !important;
  }

  .sideImages .bottomImg .slick-slider img {
    height: auto !important;
  }
}

@media (max-width: 768px) {
  .slick-dots li {
    margin: 0 -2px;
    width: 15px;
    height: 15px;
  }
}

.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
}

.carousel-fade .carousel-item.active {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.slick-slide img.mobImage {
  display: none !important;
}

.slick-slide img.deskImage {
  display: block !important;
}

@media (max-width: 640px) {
  .slick-slide img.mobImage {
    display: block !important;
  }

  .slick-slide img.deskImage {
    display: none !important;
  }
}

.cardText {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.landing__footer {
  background: linear-gradient(
    76.08deg,
    rgba(1, 76, 126, 0.5) -8.73%,
    rgba(47, 131, 185, 0.29) -8.73%,
    rgba(47, 131, 185, 0.12) 13.79%,
    rgba(133, 201, 244, 0.15) 32.34%,
    rgba(30, 175, 5, 0.05) 50.91%,
    rgba(47, 131, 185, 0.185) 66.6%,
    rgba(31, 173, 8, 0.06) 90.19%
  );
}

.bannertext {
  height: auto;
}

@media (max-width: 768px) {
  .bannertext {
    height: 750px;
  }

  .textContent {
    top: 45%;
  }
}

@media (max-width: 475px) {
  .bannertext {
    height: 770px;
  }

  .textContent {
    top: 44%;
  }
}

@media (max-width: 425px) {
  .bannertext {
    height: 800px;
  }

  .textContent {
    top: 43%;
  }
}

@media (max-width: 375px) {
  .bannertext {
    height: 840px;
  }

  .textContent {
    top: 41%;
  }
}

/* @media (max-width: 1340px) {
  .bannertext {
    left: 40px;
  }
} */

.cardCount {
  position: absolute;
  right: 10px;
  top: 70px;
  color: rgba(0, 0, 0, 0.01);
  font-size: 180px;
  line-height: 24px;
  font-weight: 700;
  font-family: "Montserrat", "sans-serif";
}

@media (max-width: 640px) {
  .cardCount {
    font-size: 119px;
    right: 5px;
    top: 40px;
  }
}

@media (max-width: 639px) {
  .boxSpacing {
    margin-top: 400px;
  }
}

@media (max-width: 500px) {
  .boxSpacing {
    margin-top: 450px;
  }
}

@media (max-width: 426px) {
  .boxSpacing {
    margin-top: 500px;
  }
}

@media (max-width: 375px) {
  .boxSpacing {
    margin-top: 550px;
  }
}

.sideDrawer {
  z-index: 20000000099999 !important;
  position: relative;
}

.titleOwerflow {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* .customize__jewelry{
  background-image: url('./assets/images/productBg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 4px;
} */

.d-flex {
  display: flex !important;
}

.bottomImg .slick-dots li {
  margin: 0;
}

.shadowBox {
  box-shadow: 6px 8px 18px -7px #504f4f;
}

.shapeBox {
  clip-path: polygon(
    4% 0,
    96% 0,
    100% 3%,
    100% 97%,
    96% 100%,
    4% 100%,
    0 97%,
    0 3%
  );
}

.boxShadow{
  box-shadow: 0px 2px 11px 0px #f1f5f9;
}

.productTitle{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -ms-box;
  display: -o-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
}

@media (max-width: 640px) {
  .productTitle{
    font-size: 13px;
    height: 39px;
  }
  .productPrice{
    font-size: 13px;
  }
}