/* @import "tailwindcss/base";
@import "tailwindcss/Components/Admin";
@import "tailwindcss/utilities"; */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Waterfall&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Konkhmer+Sleokchher&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comme:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap');

@font-face {
  font-family: "Big Caslon";
  src: url("./assets/fonts/BigCaslon-Medium.woff2") format("woff2"),
    url("./assets/fonts/BigCaslon-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gurmukhi MN";
  src: url("./assets/fonts/Gurmukhi\ MN.ttc") format("ttc");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Amasis MT";
  src: url("./assets/fonts/amasis-mt.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Fairwater Script Bold";
  src: url("./assets/fonts/Fairwater\ Script\ Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Arial";
  src: url("./assets/fonts/arialbd.ttf") format("ttf");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Aharoni";
  src: url("./assets/fonts/ahronbd.ttf") format("ttf");
}

@font-face {
  font-family: "Segoe Ui";
  src: url("./assets/fonts/Segoe\ UI.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDialog-scrollPaper {
  transition: all 0.5s ease-in-out !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
  box-shadow: none;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

@media (max-width: 1400px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.FooterBg,
.headerBg {
  background: linear-gradient(
    76.08deg,
    rgba(1, 76, 126, 0.5) -8.73%,
    rgba(47, 131, 185, 0.29) -8.73%,
    rgba(47, 131, 185, 0.12) 13.79%,
    rgba(133, 201, 244, 0.15) 32.34%,
    rgba(30, 175, 5, 0.05) 50.91%,
    rgba(47, 131, 185, 0.185) 66.6%,
    rgba(31, 173, 8, 0.06) 90.19%
  );
}

.checkout_bg {
  background: linear-gradient(
    71.76deg,
    rgba(255, 255, 255, 0.56) 4.35%,
    rgba(255, 255, 255, 0.49481) 21.69%,
    rgba(248, 234, 216, 0.3248) 33.9%,
    rgba(234, 212, 198, 0.3472) 47.78%,
    rgba(236, 208, 223, 0.56) 59.2%,
    rgba(248, 234, 216, 0.175) 73.47%,
    rgba(208, 184, 168, 0.102083) 83.46%,
    rgba(255, 255, 255, 0) 98.91%
  );
}

.admAddProduct {
  height: calc(100vh - 65px);
}

.shadowClass {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.diamondTable {
  height: calc(100vh - 130px);
  overflow: auto;
}

.diamondTable::-webkit-scrollbar-track,
.filterHeight::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.diamondTable::-webkit-scrollbar,
.filterHeight::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
}

.diamondTable::-webkit-scrollbar-thumb,
.filterHeight::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.checkBoxData {
  position: absolute;
  top: 5px;
  right: 5px;
}

.checkBoxData svg circle {
  fill: #64748b;
  opacity: 1;
}

.textFont {
  font-size: 14px !important;
}

.bottomBg {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1601890756302521) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.service-card:hover img {
  filter: invert(1);
}

.service-card p {
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

@media (max-width: 1024px) {
  .service-card {
    max-height: 287px;
  }
}

.customer-story-slider .slick-prev,
.customer-story-slider .slick-next {
  display: none !important;
}

.main-page-btn:hover .btn-hover-left-right {
  width: 100%;
  right: 0;
  z-index: -1;
  transition: 0.3s;
  opacity: 1;
}

.mainfooter-media::before {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #024c79;
  transform: translate(-50% -50%);
  transition: 0.3s;
  border-radius: 50%;
  z-index: -1;
}

.mainfooter-media:hover::before {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.hover-blue-shadow:hover {
  box-shadow: 2px 2px 10px rgba(2, 75, 121, 0.6);
}

.filterHeight {
  height: calc(100vh - 150px);
}

.sliderGrid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.textimonial .slick-list{
  margin: 0;
}
