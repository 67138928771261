@media (max-width: 1400px) {

    .category-slider .slick-prev,
    .slick-next {
        display: block !important;
    }
}

.slick-prev,
.slick-next {
    border-radius: 50%;
}

.slick-prev{
    left: -32px;
}

.slick-prev:focus,
.slick-next:focus .slick-prev:hover,
.slick-next:hover {
    border-radius: 50%;
}

.slick-prev:before,
.slick-next:before {
    font-size: 26px;
    vertical-align: middle;
    color: #1F2937 !important;
    background: transparent !important;
    opacity: 1 !important;
    overflow: hidden;
    /* position: absolute;
    top: -6px;
    left: -7px;
    right: -4px;
    bottom: -4px;
    /* border: 1px solid #101010; */
    /* border-left: 1px solid #101010; */
    /* border-right: 0.5px solid rgba(16, 16, 16, 0.2); */
    /* border-radius: 50%;
    padding: 3px 2px; */
}

.search-box {
    background: #113142;
}

/* .search-box input {
    line-height: 48px;
    border-radius: 30px;
} */

.search-box .search-icon {
    display: block;
    line-height: 42px;
    border: 0;
    outline: 0;
}

.category-box {
    display: block !important;
    max-width: 230px;
    height: 66px;
    /* border-radius: 6px; */
    transition: .3s;
    margin: 0 auto;
    border: 1px solid #e5e7eb;
}

.category-box:hover {
    background: #F6F7F9;
}

.category-box img {
    transform: scale(0.7);
    transition: .3s;
}

.category-box:hover img {
    transform: scale(0.8);
}

.category-box h4 {
    transform: scale(1);
    transition: .3s;
}

.category-box:hover h4 {
    transform: scale(1.02);
}


@media (max-width: 560px) {

    .category-slider .slick-prev,
    .slick-next {
        display: none !important;
    }

}