.zoom-in-out-box {
    animation: zoom-in-zoom-out 0.1s ease;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0, 0);
    }

    25% {
        transform: scale(0.25, 0.25);
    }

    50% {
        transform: scale(0.50, 0.50);
    }

    75% {
        transform: scale(0.75, 0.75);
    }

    100% {
        transform: scale(1, 1);
    }
}