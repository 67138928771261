.svg-2,
.svg-4,
.svg-6 {
  display: none !important;
}

.banner-box:hover .svg-6 {
  display: block;
}

.banner-box:hover img {
  filter: invert(1);
}

.banner-box:hover .banner-lable {
  background-color: white !important;
  color: #1f2937 !important;
}

.banner-box:hover .banner-text {
  color: white !important;
}

.banner-box {
  width: 100%;
  max-width: 350px;
  margin: auto;
  transition: all 1s;
}

.banner-box:hover {
  background: linear-gradient(
    76.08deg,
    rgba(1, 76, 126, 0.5) -8.73%,
    rgba(47, 131, 185, 0.29) -8.73%,
    rgba(47, 131, 185, 0.12) 13.79%,
    rgba(133, 201, 244, 0.15) 32.34%,
    rgba(30, 175, 5, 0.05) 50.91%,
    rgba(47, 131, 185, 0.185) 66.6%,
    rgba(31, 173, 8, 0.06) 90.19%
  );
  background-color: #1F2937;
}

/* .banner-box:hover .img-fix img {
  transform: rotateY(180deg);
} */

@media only screen and (max-width: 560px) {
  .banner-box {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: none;
  }

  .banner-box .img-fix {
    display: flex;
    align-items: center;
    width: 46px;
    min-height: 60px;
    height: auto;
    margin: 10px 20px 10px 24px;
  }

  .banner-box .img-fix img {
    max-width: 40px;
  }

  .banner-box .banner-lable {
    /* height: 100%; */
    font-size: 18px;
    text-align: left;
    border: 0;
    margin: 0;
    padding: 4px 2px 0;
  }

  .banner-box:hover .banner-lable {
    color: white !important;
    background-color: transparent !important;
  }

  .mx-0-after560 {
    text-align: left;
    padding: 0px 0;
    margin: 0;
  }

  .mx-0-after560 p {
    padding: 0 6px 8px;
  }
}
